<template>
<div>
  <v-card class="ma-0 pa-0 elevation-0 white" style="max-height:100%">
   <div class=" my-border">
     <v-card-title class="py-0 my-0 customlightgray">
       <v-row class="ma-0 pa-0 py-2 justify-center text-left">
            <v-col class="pa-0 ma-auto" cols="6" sm="4" md="4" lg="4" order="1" order-md="1">
              <div class="font-weight-medium text-left text--secondary">  
                  <div class="ma-auto pa-auto">
                    <span class="ma-0 pa-1">{{$t('Templates')}}</span>
                    <span style=" border-radius: 22px; font-size:0.6em;"
                            class="custom-color-accent pa-1">{{templates.length}}
                        </span>
                  </div>
              </div>
            </v-col>
            <v-col class="ma-0 pa-0 text-center" order="3" order-md="2"
              cols="12" 
              sm="12"
              md="4"
              lg="4">  
                  <v-text-field
                    v-model="search"
                    class="ma-0 pa-0"
                    append-icon="mdi-magnify"
                    :label="$t('search')"
                    dense 
                    outlined
                    clearable
                    hide-details
                  >
                  <template v-slot:prepend-inner>
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-on='on' v-bind="attrs" @click="dialog_adv=true" class="text--secondary">mdi-cog</v-icon>
                        </template>
                        <span>{{$t("advanced")}}</span>
                      </v-tooltip>
                    </template>
                  </v-text-field> 
            </v-col>
            <v-col class="text-right pa-0 my-2 ma-md-auto" cols="6" sm="8" md="4" lg="4" order="2" order-md="3"> 
              <v-btn class="custom-color-accent" depressed @click="newTemplate()">{{$t('New Template')}}</v-btn>
            </v-col>
        </v-row>
      </v-card-title>
      <v-dialog v-model="dialog_adv" max-width="600px">
        <v-card class="customoffwhite">
          <v-card-title class="justify-center ma-0 customlightgray">
            <span class="font-weight-light">{{$t("advanced")}}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="ma-0 pa-0">
            <v-container class="ma-0">
              <v-row class="ma-0">
               <v-col cols="12" class="ma-0 py-0">
                 <v-autocomplete v-model="selectedlanguage" hide-details dense outlined :items="getLookup('Language')" :label="$t('Language')" :placeholder="$t('Language')" clearable></v-autocomplete>
               </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="ma-0 py-0">
            <v-spacer></v-spacer>
            <v-btn class="custom-color-accent-text" text @click="dialog_adv = false">{{$t("close")}}</v-btn>
            <v-btn class="custom-color-accent-text" text @click="setLanguage(selectedlanguage),dialog_adv=false,selectedlanguageCopy=selectedlanguage">{{$t("search")}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-divider  v-show="selectedlanguageCopy"></v-divider>       
         <v-row class="pa-0 ma-0 white" v-show="selectedlanguageCopy">
            <v-col class="pa-0 ma-0" cols="12"> 
              <v-scroll-x-transition hide-on-leave>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip  v-on='on' v-bind="attrs"
                    class="ma-1"
                    color="custom-color-accent"
                    close
                    small
                    close-icon="mdi-delete"
                    @click:close="setLanguage(null),selectedlanguageCopy=null">
                      <span class="text-capitalize">{{selectedlanguageCopy=='E'?$t('english'):$t('french')}}</span>
                    </v-chip>
                  </template>
                  <span>{{$t("removefilter")}}</span>
                </v-tooltip>
                </v-scroll-x-transition>
            </v-col>
        </v-row>
         
      <v-divider></v-divider>
    
    <v-data-table :mobile-breakpoint="1081" 
        :items="templates"
        v-model="selected_template"
        :headers="headers"
        :search="search"
        class="elevation-0 customoffwhite text--secondary pa-0 ma-0 clickable"
        :loading-text="$t('Loading')"
        :footer-props="{
          'items-per-page-text':$t('rowsperpage')}"
        item-key="id"
        @click:row="editTemplate"
    >
      <template v-slot:no-data> {{$t('noDataAvailable')}}</template>
      <template class="pa-0 ma-0" v-slot:[`item.action`]="{ item }">
          <v-row class="ma-0 pa-0 text-right">
            <v-col cols="12" class="ma-0 pa-0">
               <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-on='on' v-bind="attrs" icon>
                    <v-icon
                      @click.stop="deleteTemplate(item)">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{$t("delete")}}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </template>
    </v-data-table>
   </div>
  </v-card>

      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title>{{$t('delete')}}</v-card-title>
          <v-card-text>{{$t('Are you sure you want to delete this configuration?')}}</v-card-text>
          <v-card-actions>
            <v-btn class="custom-color-accent-text" text @click="dialogDelete = false">{{$t('Close')}}</v-btn>
            <v-btn class="custom-color-accent-text" text @click="deleteTemp()">{{$t('delete')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <v-card v-if="dialog" class="ma-0 mt-4 pa-0 elevation-0 white" style="max-height:100%">
      <div class=" my-border">
        <v-card-title class="py-0 my-0 customlightgray">
          <v-row class="ma-0 pa-0 py-2 justify-center text-left">
              <v-col class="pa-0 ma-auto" cols="6" sm="4" md="4" lg="4" order="1" order-md="1">
                <div class="font-weight-medium text-left text--secondary">  
                    <div class="ma-auto pa-auto">
                      <span class="ma-0 pa-1" v-if="edit">{{$t('Edit Template')}}</span>
                      <span class="ma-0 pa-1" v-else>{{$t('New Template')}}</span>
                    </div>
                </div>
              </v-col>
              <v-col class="ma-0 pa-0 text-center" order="3" order-md="2"
                cols="12" 
                sm="12"
                md="4"
                lg="4">  
              </v-col>
              <v-col class="text-right pa-0 my-2 ma-md-auto" cols="6" sm="8" md="4" lg="4" order="2" order-md="3"> 
                <v-btn
                  class="custom-color-accent-text"
                  text
                  @click="close">
                  {{$t('cancel')}}
                </v-btn>
                <v-btn
                  class="custom-color-accent ml-2"
                  text
                  :disabled="!isValid()"
                  @click="save">
                  {{$t('save')}}
                </v-btn>
              </v-col>
            </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-left">
          <span class="description">
              <v-tooltip v-for="propriety in proprieties" top :key="propriety.id">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip v-on='on' v-bind="attrs" class="ma-2 text-left" @click="copyToClipBoard(propriety.code)">{{$t(propriety.name)}}( {{propriety.code}} )</v-chip>
                </template>
                <span>{{$t("Copy to clipboard")}}</span>
              </v-tooltip>
          </span>
          <div>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12">
                <v-row class="pa-0 ma-0">
                  <v-col cols="8" class="pa-2 ma-0"><v-text-field v-model="title" hide-details dense outlined :label="$t('templatename')" :placeholder="$t('templatename')"></v-text-field></v-col>
                  <v-col cols="4" class="pa-2 ma-0"><v-autocomplete v-model="lang" hide-details dense outlined :items="getLookup('Language')" :label="$t('Language')" :placeholder="$t('Language')" clearable></v-autocomplete></v-col>
                </v-row>
              </v-col>
              <v-col
                  cols="12"
                  sm="12"
                  md="12"
              >
                <el-tiptap
                    v-model="content"
                    :extensions="extensions"
                    :lang="$i18n.locale"
                    class="white"
                />  
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </div>
    </v-card>
  </div>
</template>

<script>
import {mapState, mapActions,mapGetters} from "vuex";
import { ElementTiptap } from 'element-tiptap';
import codemirror from "codemirror";
import "codemirror/lib/codemirror.css"; // import base style
import "codemirror/mode/xml/xml.js"; // language
import "codemirror/addon/selection/active-line.js"; // require active-line.js
import "codemirror/addon/edit/closetag.js"; // autoCloseTags
import {
  // necessary extensions
  Doc,
  Text,
  Paragraph,
  Heading,
  Bold,
  Underline,
  Italic,
  Strike,
  ListItem,
  BulletList,
  OrderedList,
  TextAlign,
  History,
  Image,
  CodeView,
  Table,
  TableHeader,
  TableCell,
  TableRow,
  Link
} from 'element-tiptap';
import axcessApi from "@/plugins/axcessApi";

export default {
  name: "Templates",
  components: { 'el-tiptap': ElementTiptap },
  data() {
    return {
      extensions: [
        new Doc(),
        new Text(),
        new Paragraph(),
        new Heading({ level: 5 }),
        new Bold({ bubble: true }), // render command-button in bubble menu.
        new Underline({ bubble: true, menubar: false }), // render command-button in bubble menu but not in menubar.
        new Italic(),
        new Strike(),
        new ListItem(),
        new BulletList(),
        new OrderedList(),
        new TextAlign(),
        new History(),
        new Image(),
          new Link({bubble:true}),

        new TableHeader(),
        new TableCell(),
        new TableRow(),
        new Table({
          resizable: true,
        }),
        new CodeView({
          codemirror,
          codemirrorOptions: {
            styleActiveLine: true,
            autoCloseTags: true
          }
        })
      ],
      proprieties:[
              {id:1,name:'Request Code',code:'$reqCode'},
              {id:2,name:'Request Date',code:'$date'},
              {id:3,name:'Requester Title',code:'$address.Title'},
              {id:4,name:'Requester Firstname',code:'$address.FirstName'},
              {id:5,name:'Requester Lastname',code:'$address.LastName'},
              {id:6,name:'Requester Company/Agency',code:'$address.CompanyAgency'},
              {id:7,name:'Requester Address Number',code:'$address.AddrNum'},
              {id:8,name:'Requester Address Street',code:'$address.Address'},
              {id:9,name:'Requester Address City',code:'$address.City'},
              {id:10,name:'Requester Address Province',code:'$address.Province'},
              {id:11,name:'Requester Address Postal code',code:'$address.PostalCode'},
              {id:12,name:'Request date Received',code:'$DateReceived'},
              {id:13,name:'ATIP Officer Official Contact number',code:'$user.phone1'},
              {id:14,name:'ATIP Officer Official email',code:'$user.email'},
              {id:15,name:'ATIP Officer firstname',code:'$user.first_name'},
              {id:16,name:'ATIP Officer lastname',code:'$user.last_name'},
          ],
      selectedlanguage:"",
      dialog_adv:false,
      selectedlanguageCopy:null,
      selected_template:[],
      search: "",
      headers: [
        {
          text: this.$t("ID"),
          align: "left",
          value: "id"
        },
        { text: this.$t("Language"), value: "fulllanguage.LookUpStringE" },
        { text: this.$t("templatename"), value: "name" },
        { text: "", value: "action", sortable: false }
        
      ],
      title:"",
      content:"",
      dialog:false,
      dialogDelete: false,
      edit: false,
      new: false,
    }
  },
  async created() {
    await this.retriveTemplate();
  },
  computed:{
    formTitle() {
      return this.template === -1 ? "New Request" : "Edit Request";
    },
    ...mapGetters('template', {
      templates: 'getTemplatebyLanguage'
      }),
    ...mapState("configuration", {
      configurations: "configurations"
    }),
  },
  methods:{
       copyToClipBoard(content) {
         /*  content.select();
          document.execCommand('copy'); */
          navigator.clipboard.writeText(content)
          .then(() => {
            this.$root.$emit("callAlert", {
              text: this.$t("textcopied"),
              type: "dark",
              alert: true
            });
          })
          .catch(() => {
            this.$root.$emit("callAlert", {
              text: this.$t("Something went wrong"),
              type: "warning",
              alert: true
            });
          })
      },
        isValid(){
          return this.title!=''&&this.lang!=''&&this.content!=''
        },
        getlookupvalue(lookupname,val){
        return this.configurations.filter( filter => filter.LookUpName === lookupname &&filter.LookUpKey== val ).map( item => {
             let status = "";
            status = localStorage.getItem('language') === 'en' ? item.LookUpStringE : item.LookUpStringF;
            return status;
          })[0]
         },

      getLookup( column ){
      return this.configurations.filter( filter => filter.LookUpName === column ).map( item => {
        let arr = [];
        arr['text'] = localStorage.getItem('language') === 'en' ? item.LookUpStringE : item.LookUpStringF;
        arr['value'] = item.LookUpKey;
        return  arr;
      })
    },

    deleteTemplate(item){
      this.deletedItem = item;
      this.dialogDelete = true;

    },
    deleteTemp(){
      axcessApi.deleteRequest("/template/" + this.deletedItem.id ).then(()=>{
        let color="dark";
        let alert="templateDeleteAlert";
        this.$root.$emit("callAlert", {text: this.$t(alert), type: color, alert: true });
        this.retriveTemplate();
        this.close();
      })
    },
    async editTemplate(item){
      this.selected_template=[];
      this.selected_template.push(item);
      axcessApi.getRequest("/template/" + item.id).then(r=>{
        this.dialog = true;
        this.title = r.data.name;
        this.content = r.data.content;
        this.edit = r.data.id;
        this.lang = r.data.language;
      });

    },
    newTemplate(){
      this.close()
      this.dialog = true;
      this.new= true;
    },
    close(){
      this.dialog= false;
      this.dialogDelete = false;
      this.edit= false;
      this.title = '';
      this.content = '';
      this.edit = '';
      this.lang = '';
      this.selected_template=[];
    },
    save(){
        let data = {
          name: this.title,
          content: this.content,
          language:this.lang
        }
        let color="success";
        let alert="templateAddAlert";
        if(this.edit){
          data.id = this.edit;
          color="dark";
          alert="templateEditAlert";
        }
        this.setTemplate(data).then(()=>{this.$root.$emit("callAlert", {text: this.$t(alert), type: color, alert: true });});
        this.close()
    },



    ...mapActions('template',['setTemplate','getTemplate','retriveTemplate','setLanguage'])
  }
}
</script>

<style>
.el-tiptap-editor__menu-bar,
.el-tiptap-editor__content,
.el-tiptap-editor__footer,
.el-tiptap-editor__command-button,
.el-dropdown{
  background: inherit !important;
  color: inherit !important;
}
.el-tiptap-editor__command-button:hover{
  background: rgba(143, 142, 142, 0.363) !important;
}
.el-tiptap-editor__command-button--active{
  color: white !important;
  background: rgb(95, 95, 95) !important;
}
.el-tiptap-dropdown-menu__item--active,.CodeMirror-activeline-background {
  color: white !important;
  background: rgba(122, 122, 122, 0.5) !important;
}
.el-popper {
  background: rgb(63, 63, 63) !important;
}
.el-dropdown-menu__item,.el-tiptap-popper__menu__item,.el-dialog__header,.el-dialog__body,.el-dialog__title,.el-form-item__label,.el-message-box__title{
  color:white !important;
}
.el-dropdown-menu__item:hover,.el-tiptap-popper__menu__item:hover {
  color:white !important;
  background: rgb(59, 59, 59) !important;
}
.el-dialog,.el-message-box,.el-input__inner,.CodeMirror,.CodeMirror-gutters,.el-tiptap-editor__menu-bubble{
  background: rgb(49, 49, 49);
  color: white !important;
}
</style>

